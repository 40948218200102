var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container pb-0 mb-2"},[_c('div',{attrs:{"id":"filter-custom"}},[_c('h3',{staticClass:"header"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Người lao động "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.value; },"label":"label","options":_vm.isOversea,"placeholder":'Lựa chọn người lao động'},on:{"input":_vm.filterWorker},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Loại hợp đồng lao động "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.value; },"label":"label","options":_vm.contractType,"placeholder":'Lựa chọn loại hợp đồng lao động'},on:{"input":_vm.filterTypeContract},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Trình độ học vấn "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.value; },"label":"label","options":_vm.academicLevel,"placeholder":'Lựa chọn trình độ học vấn'},on:{"input":_vm.filterStudy},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1),_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Doanh nghiệp")]),_c('v-select',{attrs:{"reduce":function (title) { return title.id; },"label":"name","disabled":_vm.userData.roleIdentity === 'EducationDepartment',"options":_vm.dataAllBusiness || [],"placeholder":'Doanh nghiệp'},on:{"input":_vm.filterBussiness},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}]),model:{value:(_vm.urlQuery.bussinessId),callback:function ($$v) {_vm.$set(_vm.urlQuery, "bussinessId", $$v)},expression:"urlQuery.bussinessId"}})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Trạng thái "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.status; },"label":"statusString","options":_vm.status,"placeholder":'Lựa chọn trạng thái'},on:{"input":_vm.filterStatus},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1)],1)],1)]),_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm người lao động',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDelete":_vm.deleteItems,"clickDowloadSample":_vm.dowloadFile,"clickExportExcel":_vm.downloadExcelAll,"importFile":function($event){return _vm.importFileExcel($event)},"clickAdd":function($event){return _vm.$router.push({name: 'manage-workers-add'})},"search":function($event){return _vm.search($event)}}}),[_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataTable || [],"style-class":"vgt-table","select-options":{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'statusString' )?_c('span',[_c('b-badge',{staticClass:"border-status",attrs:{"pill":"","variant":_vm.colorStatus(props.row.status)}},[_c('span',[_vm._v(" "+_vm._s(props.row.statusString))])])],1):(props.column.field == 'cn')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.$router.push({name: 'manage-workers-edit', params: {id: props.row.id}})}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticStyle:{"margin-left":"14px"},attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(props.row.id)}}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}})],_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"title":'',"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }