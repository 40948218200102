export default {
  DO_FETCH_LIST_WORKERS: 'Worker/workers-department',
  FETCH_DATA_ID_ST: 'Worker/workers/',
  ADD: 'Worker/workers',
  EDIT_ST: 'Worker/workers',
  FECTH_COUNTRY: 'OfficalProfile/countries',
  GET_ALL_COMBOBOX: 'Position/get-combobox-position',
  DO_DELETE_WORKER: 'Worker/delete-worker',
  DOWLOAD_FILE: 'Worker/generateTemplate',
  DOWNLOAD_EXCEL_ALL: 'Worker/export-excel',
  GET_API_EXCEL: 'Worker/import-worker-from-json',
  LIST_WORKER_PROGRESS: 'Worker/list-worker-progress',
  CREATE_WORKER_PROGRESS: 'Worker/add-worker-progress',
  DELETE_WORKER_PROGRESS: 'Worker/delete-worker-progress',
  LIST_OVERTIME_WORKING: 'Worker/list-worker-over-time',
  CREATE_OVERTIME_WORKING: 'Worker/add-worker-over-time',
  DELETE_OVERTIME_WORKING: 'Worker/delete-worker-over-time',
  COMBOBOX_WORKING_FORM: 'Worker/get-combobox-working-form',
}
