export default {
  dataStatus: [
    {
      status: 'None',
      statusString: 'Là lao động trong nước, không cần cấp phép',
    },
    {
      status: 'NonLiscening',
      statusString: 'Chưa cấp phép',
    },
    {
      status: 'PendingConfirm',
      statusString: 'Đang chờ xác nhận không thuộc diện cấp giấy phép',
    },
    {
      status: 'Confirm',
      statusString: 'Xác nhận không thuộc diện cấp giây phép',
    },
    {
      status: 'RejectConfirm',
      statusString: 'Từ chối xác nhận',
    },
    {
      status: 'PendingLicensing',
      statusString: 'Đang chờ duyệt cấp phép',
    },
    {
      status: 'Licensing',
      statusString: 'Duyệt cấp phép',
    },
    {
      status: 'RejectLicense',
      statusString: 'Từ chối cấp phép',
    },
    {
      status: 'PendingRenew',
      statusString: 'Đang chờ duyệt cấp lại',
    },
    {
      status: 'Renew',
      statusString: 'Đã cấp lại',
    },
    {
      status: 'PendingExtend',
      statusString: 'Đang chờ duyệt gia hạn',
    },
    {
      status: 'Extend',
      statusString: 'Đã gia hạn',
    },
    {
      status: 'Revoke',
      statusString: 'Thu hồi giấy phép',
    },
  ],
  dataWorkerLicenseType: [
    {
      workerLicenseType: 'License',
      workerLicenseTypeString: 'Giấy cấp phép',
    },
    {
      workerLicenseType: 'Confirm',
      workerLicenseTypeString: 'Giấy xác nhận',
    },
  ],
  isOversea: [ // Lao động trong nước hay ngoài nước
    {
      label: 'Người nước ngoài.',
      value: true,
    },
    {
      label: 'Người trong nước.',
      value: false,
    },
  ],
  contractType: [ // lọc theo loại hợp đồng
    {
      label: 'Hợp đồng lao động vô thời hạn',
      value: 'Unlimited',
    },
    {
      label: 'Hợp đồng lao động có thời hạn',
      value: 'Limited',
    },
    {
      label: 'Hợp đồng lao động khác',
      value: 'Another',
    },
    {
      label: ' Hợp đồng lao động dưới 2 năm',
      value: 'Under2Year',
    },
  ],
  academicLevel: [ // Lọc theo trình độ học vấn
    {
      label: 'Đại học',
      value: 'University',
    },
    {
      label: 'Cao đẳng',
      value: 'College',
    },
    {
      label: 'Trung cấp',
      value: 'Intermediate',
    },
    {
      label: 'Sơ cấp nghề',
      value: 'Primary',
    },
    {
      label: 'Chưa qua đào tạo',
      value: 'None',
    },
  ],
}
